import React, { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { rem } from 'design-system/values';
import Section from 'design-system/Section';
import { CardView } from 'design-system/CardView';
import { TextButtonLink } from 'design-system/TextButton';
import { selectBudgetedMerchants, selectCategoriesLoadingState, selectMerchantsLoadingState, } from 'features/budgeting/selectors';
import { useAppSelector } from 'store/hooks';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import { isNotLoadedState } from 'reducers/types';
import MerchantBudgetRow from './MerchantBudgetRow';
import MerchantBudgetAddRow from './MerchantBudgetAddRow';
import MerchantBudgetEmptyCard from './MerchantBudgetEmptyCard';
const BudgetingMerchantsList = ({ isCurrentPeriod, onPressMerchant, onPressAdd, withSection = true, }) => {
    const navigation = useMainStackNavigation();
    const merchants = useAppSelector(selectBudgetedMerchants);
    const merchantsLoadingState = useAppSelector(selectMerchantsLoadingState);
    const categoriesLoadingState = useAppSelector(selectCategoriesLoadingState);
    const handleEditPress = useCallback(() => {
        navigation.navigate('EditMerchantBudgets');
    }, []);
    const handlePress = useCallback((item) => {
        onPressMerchant(item);
    }, [onPressMerchant]);
    if (isNotLoadedState(categoriesLoadingState)) {
        return null;
    }
    if (!merchants.length) {
        if (isNotLoadedState(merchantsLoadingState)) {
            return null;
        }
        if (!isCurrentPeriod) {
            return null;
        }
    }
    const renderMerchantItems = () => (<CardView noMarginTop webPadding style={!withSection && styles.card}>
      {isCurrentPeriod && <MerchantBudgetAddRow onPress={onPressAdd}/>}
      {merchants.map((item) => (<MerchantBudgetRow key={item.id} item={item} onPress={handlePress}/>))}
    </CardView>);
    // No merchants with section - show empty state
    if (withSection && !merchants.length) {
        return <MerchantBudgetEmptyCard onPress={onPressAdd}/>;
    }
    // Has merchants with section - show in a section container
    if (withSection) {
        return (<Section title="Merchant budgets" style={styles.section} button={isCurrentPeriod ? (<TextButtonLink text="Edit" onPress={handleEditPress} testID="editMerchantBudgets"/>) : null}>
        {renderMerchantItems()}
      </Section>);
    }
    // No section - just render the merchants card directly
    return renderMerchantItems();
};
const styles = StyleSheet.create({
    section: {
        marginTop: rem(24),
        marginBottom: 0,
    },
    card: {
        marginTop: rem(16),
    },
});
export default memo(BudgetingMerchantsList);

import React, { memo, useCallback } from 'react';
import Animated from 'react-native-reanimated';
import { View, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Links from 'utils/links';
import { useHeaderHeight } from 'hooks';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import createStyleSheets from 'utils/createStyleSheets';
import TouchableOpacity from 'utils/packages/TouchableOpacity';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMainStackNavigation } from 'utils/types/navigationV6';
import Text from './Text';
import { rem } from './values';
import Squircle from './Squircle';
import { AppView } from './AppView';
import { isWeb } from '../constants';
import { CardView } from './CardView';
import BottomPanel from './BottomPanel';
import Button, { HEIGHT } from './Button';
const FeatureIntroduction = ({ title, subTitle, items, buttonText, onPressNext, topIcon, pdf, tncUrl, scrollHandler, titleContainerStyle, }) => {
    const navigation = useMainStackNavigation();
    const colors = useColors();
    const styles = useStyles(styleSet);
    const headerHeight = useHeaderHeight();
    const { bottom } = useSafeAreaInsets();
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const onPressTerms = useCallback(() => {
        if (tncUrl) {
            if (pdf && Platform.OS === 'android') {
                navigation.navigate('Pdf', {
                    url: tncUrl,
                    buttonTitle: 'Done',
                });
            }
            else {
                Links.openLink(tncUrl);
            }
        }
    }, [pdf, tncUrl]);
    return (<AppView type="secondary" withFrame={false}>
      <Animated.ScrollView scrollEventThrottle={16} onScroll={scrollHandler} style={styles.scroll} contentContainerStyle={[
            paddingHorizontalStyle,
            {
                paddingTop: scrollHandler ? 0 : headerHeight + rem(24),
                paddingBottom: bottom + HEIGHT,
            },
        ]}>
        <View style={styles.wrapper}>
          <CardView style={styles.cardView}>
            <View style={[styles.titleContainer, titleContainerStyle]}>
              <Text Text-20 style={styles.title}>
                {title}
              </Text>
              {typeof subTitle === 'string' ? (<Text TextThin-14 Secondary style={styles.subTitle}>
                  {subTitle}
                </Text>) : (subTitle)}
            </View>

            {items.map((item, index) => (<View key={index} style={styles.itemContainer}>
                <Squircle style={styles.iconContainer} color={colors.background.dark}>
                  {item.icon}
                </Squircle>
                <View style={styles.textContainer}>
                  <Text Text-16 OffBlack>
                    {item.title}
                  </Text>
                  <Text TextThin-14 Secondary>
                    {item.description}
                  </Text>
                </View>
              </View>))}
          </CardView>
          <View style={styles.squircleView}>
            <Squircle color={colors.borders.logoStroke}>
              <Squircle height={rem(42)} width={rem(42)} color={colors.cards.onDark}>
                {topIcon}
              </Squircle>
            </Squircle>
          </View>
        </View>
      </Animated.ScrollView>

      {tncUrl ? (<BottomPanel style={styles.bottomPanel}>
          <TouchableOpacity onPress={onPressTerms} hoverOnDark>
            <Text TextThin-14 centered Secondary style={styles.terms}>
              T&amp;C&apos;s apply
            </Text>
          </TouchableOpacity>
          <Button title={buttonText} onPress={onPressNext} testID="nextButton" brand/>
        </BottomPanel>) : (<Button floating title={buttonText} onPress={onPressNext} testID="nextButton" brand/>)}
    </AppView>);
};
const styleSet = createStyleSheets((colors) => ({
    scroll: {
        flex: 1,
    },
    wrapper: {
        paddingTop: rem(16),
    },
    cardView: {
        position: 'relative',
        overflow: 'visible',
        marginTop: rem(24),
    },
    topIconContainer: {
        position: 'absolute',
        top: rem(-24),
        alignSelf: 'center',
        zIndex: 1,
    },
    titleContainer: {
        paddingTop: rem(38),
        borderBottomWidth: 1,
        borderBottomColor: colors.borders.divider,
        paddingHorizontal: rem(16),
    },
    title: {
        marginBottom: rem(4),
        textAlign: 'center',
    },
    subTitle: {
        marginBottom: rem(24),
        textAlign: 'center',
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: rem(16),
        width: '100%',
    },
    iconContainer: {
        marginRight: rem(16),
    },
    textContainer: {
        flex: 1,
    },
    squircleView: {
        left: 0,
        right: 0,
        top: rem(16),
        position: 'absolute',
        alignItems: 'center',
    },
    bottomPanel: {
        backgroundColor: colors.cards.onDark,
    },
    terms: {
        marginBottom: rem(16),
    },
}));
export default memo(FeatureIntroduction);

import React from 'react';
import { StyleSheet } from 'react-native';
import Modal from 'design-system/Modal';
import { rem } from 'design-system/values';
import { IconEdit } from 'design-system/icons/IconEdit';
import { SettingsItem } from 'design-system/SettingsItem';
import { IconRectangleListSolid } from 'design-system/icons/IconRectangleListSolid';
import Spacer from 'design-system/Spacer';
import PopUpMenuModal, { defaultPopUpWidth, } from 'design-system/PopUpMenuModal.web';
import { isWeb } from '../../../constants';
const CategoryEditModal = {
    show: (onPressEditBudget, onPressEditTransactions, btnViewRef) => {
        if (isWeb) {
            if (btnViewRef?.current) {
                btnViewRef.current?.measure((_, __, ___, ____, fx, fy) => {
                    PopUpMenuModal.show(<CategoryEditModalContent onPressEditBudget={onPressEditBudget} hideModal={() => PopUpMenuModal.hide()} onPressEditTransactions={onPressEditTransactions}/>, {
                        fx: fx - defaultPopUpWidth,
                        fy: fy + rem(40),
                    });
                });
            }
            return;
        }
        Modal.show(<CategoryEditModalContent onPressEditBudget={onPressEditBudget} onPressEditTransactions={onPressEditTransactions} hideModal={() => Modal.hide()}/>, {
            propagateSwipe: true,
            containerStyle: styles.modal,
            title: 'Edit',
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
});
export default CategoryEditModal;
const CategoryEditModalContent = ({ hideModal, onPressEditBudget, onPressEditTransactions, }) => {
    const handleEditBudget = () => {
        hideModal();
        onPressEditBudget();
    };
    const handleEditTransactions = () => {
        hideModal();
        onPressEditTransactions(true);
    };
    return (<>
      <Spacer h={8}/>

      <SettingsItem light={isWeb} title="Edit budget" description="Adjust category spend limits" onPress={handleEditBudget} icon={<IconEdit gradientKey="darkPurple"/>}/>

      <SettingsItem light={isWeb} title="Edit transactions" onPress={handleEditTransactions} icon={<IconRectangleListSolid />} description="Edit transactions within this category "/>
    </>);
};
